import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { LoadingPage, PrimaryButton, Seo, Text } from 'components';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import TestFor from './components/TestFor';
import Header from 'components/Header';
import { tablet, useQuery } from 'styles/breakpoints';
import Arrow from 'assets/icons/results/green-arrow-right.svg';
import BoySVG from 'assets/icons/boy.svg';
import WeightSVG from 'assets/icons/current-weight.svg';
import HeightSVG from 'assets/icons/height.svg';
import Metabolic from 'assets/icons/metabolic.svg';

import OverweightSVG from 'assets/images/weight-loss/overweight.svg';
import UnderweightSVG from 'assets/images/weight-loss/underweight.svg';
import HealthySVG from 'assets/images/weight-loss/healthy.svg';
import ResultsProgress from 'components/ResultsProgress';
import ErrorMessage from 'components/ErrorMessage';
import { Quiz } from 'types/quiz';

const ResultsMetabolism: FC = () => {
  const [loading, setLoading] = useState(false);
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;
  const user = useSelector((state: AppState) => state.user);
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );
  const { isTablet } = useQuery();
  const currentWeight = quiz_answers?.current_weight || '';
  const currentWeightUnit = quiz_answers?.current_weight_unit || '';
  const { height_cm, height_ft, height_in, height_unit, age } =
    quiz_answers || {};

  const renderHeight = () => {
    if (height_unit === 'metric') {
      return `${height_cm} cm`;
    }
    return `${height_ft}ft ${height_in}in`;
  };

  const calculateBMI = () => {
    const h = (function () {
      if (height_unit === 'metric') {
        return Number(height_cm) / 100;
      }
      const inches = Number(height_ft) * 12 + Number(height_in);
      return inches * 0.0254;
    })();
    const weightNumber = Number(currentWeight);
    let w = currentWeightUnit === 'lb' ? weightNumber / 2.205 : weightNumber;
    return w / (h * h);
  };

  const bmi = calculateBMI();

  const renderHint = () => {
    if (bmi < 18.5) {
      return <UnderweightSVG />;
    }
    if (bmi < 25) {
      return <HealthySVG />;
    }
    return <OverweightSVG />;
  };

  const { goToResultsChart } = useRouter();

  usePageView();

  const handleClick = () => {
    setLoading(true);
    goToResultsChart();
  };

  const data = useQuizData('resultsSummary');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Outter>
        <Header color="novaPink" sticky={false} />
        <ResultsProgress
          step={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
      </Outter>
      <Container>
        <InnerContainer>
          <TitleContainer>
            <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
            {data?.subtitle && <Subtitle>{data?.subtitle}</Subtitle>}
          </TitleContainer>
          <SummaryBox>
            <ItemContainer>
              <BoySVG width={48} />
              <Label>Age</Label>
              <Value>{age} years</Value>
            </ItemContainer>
            <ItemContainer>
              <WeightSVG width={48} />
              <Label>Weight</Label>
              <Value>{`${currentWeight} ${currentWeightUnit}`}</Value>
            </ItemContainer>
            <ItemContainer>
              <HeightSVG width={48} />
              <Label>Height</Label>
              <Value>{renderHeight()}</Value>
            </ItemContainer>
          </SummaryBox>
          {quiz !== Quiz.Main && (
            <BmiBox>
              <TopContainer>
                <WeightSVG width={48} />
                <YourBmiContainer>
                  <BmiLabel>Current BMI</BmiLabel>
                  <BmiValue>{bmi.toFixed(2)}</BmiValue>
                </YourBmiContainer>
              </TopContainer>
              {renderHint()}
            </BmiBox>
          )}
          <AgeBox>
            <TopContainer>
              <Metabolic width={isTablet ? 48 : 64} />
              <YourAgeContainer>
                <LabelAge>Your biological age</LabelAge>
                <Years>{Number(age) + 12} years</Years>
              </YourAgeContainer>
            </TopContainer>
            <ErrorMessageStyled
              errorText={
                Quiz.Main
                  ? 'Your biological age is higher than it should be'
                  : 'Your metabolism is slower than it should be'
              }
            />
          </AgeBox>
          <ButtonContainer>
            <ButtonStyled
              loading={loading}
              disabled={loading}
              onClick={handleClick}
            >
              <ArrowConatiner>
                <Arrow />
              </ArrowConatiner>
              {data?.buttonTitle}
            </ButtonStyled>
          </ButtonContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResultsMetabolism;

const TitleContainer = styled.section`
  padding-bottom: 1.5rem;
`;

const ErrorMessageStyled = styled(ErrorMessage)`
  width: 100%;
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  top: 0;
  z-index: 3;
`;

const Container = styled.section`
  padding: 3rem 1rem;
  background: #fef3f3;
  min-height: calc(100vh - 76px);
  @media ${tablet} {
    min-height: calc(100vh - 60px);
    padding: 1.5rem 1rem;
  }
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 26.25rem;
  margin: 0 auto;
`;

const Title = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  span {
    display: block;
    color: #ea7a74;
  }
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ArrowConatiner = styled.section`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.5rem;
  background: #fff;
  svg {
    width: 0.63538rem;
    height: 0.625rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  max-width: 28.26rem;
  margin: 0 auto;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
`;

const Box = styled.section`
  display: flex;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
`;

const AgeBox = styled(Box)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.625rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const BmiBox = styled(Box)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  @media ${tablet} {
    margin-bottom: 0.75rem;
  }
`;

const SummaryBox = styled(Box)`
  justify-content: space-between;
  margin-bottom: 1rem;
  @media ${tablet} {
    margin-bottom: 0.75rem;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
`;

const BmiLabel = styled(Text)`
  color: #1c1c28;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Label = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 0.125rem;
  @media ${tablet} {
  }
`;

const LabelAge = styled(Text)`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Value = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const YourAgeContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const YourBmiContainer = styled.div`
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;

const Years = styled(Text)`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #f47170;
  font-size: 32px;
  line-height: 45px;
  @media ${tablet} {
    font-size: 24px;
    line-height: 34px;
  }
`;

const BmiValue = styled(Text)`
  color: #1c1c28;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;
